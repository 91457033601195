import React from 'react'
import Ankle from './Ankle'
import BreadCrumbs from './BreadCrumbs'
import Footer from './Footer'
import SectionHeader from './SectionHeader'
import './TopicTemplate.scss'

const TopicTemplate = ({topicTitle, sectionTitle, image, path, children}) => {
  const style = {
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(${image})`,
  };
  return (
    <div className='TopicTemplate'>
      <SectionHeader >
      </SectionHeader>
      <div className='desktop-width-guard'>
        <BreadCrumbs topicTitle={topicTitle} sectionTitle={sectionTitle} path={path} />
        <div className="header" style={style}>
          <div className="headers">
            <h1>{topicTitle}</h1>
          </div>
        </div>
        <div className='desktop-styling'>
          <div className="crib">
            {children}
          </div>
          <Ankle>
          </Ankle>
        </div>
      </div>
      <Footer>
      </Footer>
    </div>
  )
}

export default TopicTemplate